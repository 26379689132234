import React from 'react'
import { RestorationDescription } from './Hero'
import PrimaryLink from '@components/DesignSystem/Links/PrimaryLink'
import Image from 'next/image'

export default function NewSection() {
  return (
    <section className="py-12 overflow-hidden bg-gray-50 md:py-20 lg:py-24">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col flex-col-reverse justify-between md:flex-row">
          <div className="mt-6 md:mt-0">
            <div className="flex justify-center md:justify-start">
              <RestorationDescription>ORGANIC REACH</RestorationDescription>
            </div>
            <h2
              className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-5xl sm:leading-none md:text-left lg:text-6xl"
              id="pricing"
            >
              Advanced GMB SEO
            </h2>
            <p className="max-w-2xl my-6 text-xl text-center text-gray-500 md:text-left">
              Choose an affordable plan that's packed with the best features for
              restoration needs.
            </p>
            <div className="flex justify-center md:justify-start">
              {/* <PrimaryLink variant="swag" href="/register" onClick={() => {}}>
                Learn More
              </PrimaryLink> */}
            </div>
          </div>
          <div className="overflow-hidden rounded-3xl">
            <Image
              src="/images/landing/google-3-pack.jpeg"
              width={320}
              height={445}
              alt=""
            />
          </div>
        </div>

        {/* 2 */}
        <div className="flex flex-col justify-between gap-20 mt-40 md:flex-row">
          <div className="overflow-hidden rounded-3xl">
            <Image
              src="/images/landing/website_design.jpeg"
              width={600}
              height={445}
              alt=""
            />
          </div>
          <div className="">
            <RestorationDescription>WEBSITE</RestorationDescription>
            <h2
              className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl"
              id="pricing"
            >
              Website Design
            </h2>
            <p className="max-w-2xl my-6 text-xl text-gray-500">
              Every restoration contractor should have a solid website built to
              make sure you are getting the best leads from your online traffic.
            </p>
            {/* <PrimaryLink variant="swag" href="/register" onClick={() => {}}>
              Learn More
            </PrimaryLink> */}
          </div>
        </div>

        {/* 3 */}
        <div className="flex flex-col flex-col-reverse justify-between gap-20 mt-40 md:flex-row">
          <div className="">
            <RestorationDescription>BRAND MANAGEMENT</RestorationDescription>
            <h2
              className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl"
              id="pricing"
            >
              Google LSA Ads Management
            </h2>
            <p className="max-w-2xl my-6 text-xl text-gray-500">
              Let us help increase your lead flow by helping setup and or manage
              your LSA Account
            </p>
            {/* <PrimaryLink variant="swag" href="/register" onClick={() => {}}>
              Learn More
            </PrimaryLink> */}
          </div>
          <div className="overflow-hidden rounded-3xl">
            <Image
              src="/images/landing/google_lsa_ads.jpeg"
              width={780}
              height={445}
              alt=""
            />
          </div>
        </div>

        {/* 4 */}
        <div className="flex flex-col justify-between gap-20 mt-40 md:flex-row">
          <div className="overflow-hidden rounded-3xl">
            <Image
              src="/images/landing/tpa.png"
              width={600}
              height={445}
              alt=""
            />
          </div>
          <div className="">
            <RestorationDescription>WEBSITE</RestorationDescription>
            <h2
              className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl"
              id="pricing"
            >
              TPA Contract Setup
            </h2>
            <p className="max-w-2xl my-6 text-xl text-gray-500">
              Let us help get your restoration business into some of the best
              TPA Companies. We take care of the application paperwork and
              account setup.
            </p>
            {/* <PrimaryLink variant="swag" href="/register" onClick={() => {}}>
              Learn More
            </PrimaryLink> */}
          </div>
        </div>
      </div>
    </section>
  )
}
