import { useEffect, useState } from 'react'
import PrimaryLink from '@components/DesignSystem/Links/PrimaryLink'
import { Container } from '@components/LandingPage/Container'
import { useUser } from '@supabase/auth-helpers-react'
import router from 'next/router'
import { event } from 'nextjs-google-analytics'
import type { ReactNode } from 'react'
import YouTube, { YouTubeProps } from 'react-youtube'

export const RestorationDescription = ({
  children,
}: {
  children: ReactNode
}) => (
  <span className="ml-1 text-transparent bg-gradient-to-br from-swag-dark to-swag-light bg-clip-text">
    {children}
  </span>
)

export function Hero() {
  const [showVideo, setShowVideo] = useState(false)
  const onClick = () => {
    event('start_trial', {
      category: 'Hero',
    })
  }
  const user = useUser()

  useEffect(() => {
    if (user) {
      router.push(`/projects`)
    }
  }, [user])

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo()
    setShowVideo(true)
  }

  const opts: YouTubeProps['opts'] = {
    height: '690',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  return (
    <Container className="pt-3 pb-16 text-center">
      <h1 className="max-w-full mx-auto text-5xl font-medium tracking-tight font-display text-slate-900 sm:text-7xl">
        The <RestorationDescription>#1</RestorationDescription> Free CRM
        Restoration Contractors
      </h1>

      {/* youtube video */}
      <div
        className={`relative mt-10`}
        style={{
          // paddingTop: '56.25%',
          height: '690px',
        }}
      >
        {/* <iframe
          className="absolute inset-0 w-full h-full"
          src="https://www.youtube.com/watch?v=5NQXaLtI-6M"
          frameBorder="0"
        ></iframe> */}
        <div className={`${!showVideo && `hidden`}`}>
          <YouTube videoId="5NQXaLtI-6M" opts={opts} onReady={onPlayerReady} />
        </div>
      </div>
      <p className="max-w-2xl mx-auto mt-6 text-xl tracking-tight text-slate-700">
        Take <strong>unlimited photos</strong>, manage Unlimited Jobs, Unlimited
        Dry Book Reports. Then you can write the rest
      </p>
      {/* <div className="flex justify-center mt-6 gap-x-6">
        {user ? (
          <>
            <PrimaryLink href="/projects" onClick={onClick}>
              View Projects
            </PrimaryLink>
          </>
        ) : (
          <>
            <PrimaryLink variant="swag" href="/register" onClick={onClick}>
              Make an Free Account
            </PrimaryLink>
          </>
        )}
      </div> */}
    </Container>
  )
}
